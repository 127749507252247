.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .login-box {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .login-box h1 {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .login-input {
    width: 100%;
    padding: 0.75rem;
    margin: 0.5rem 0;  /* Added margin */
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    box-sizing: border-box;  /* Ensures padding doesn't affect width */
}

.login-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #dc3545;  /* Changed to red */
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 1rem;
}

.login-button:hover {
    background-color: #c82333;  /* Darker red on hover */
}
  
  .error-message {
    color: #dc3545;
    text-align: center;
    margin-top: 1rem;
  }
  
  .success-message {
    color: #28a745;
    text-align: center;
    margin-top: 1rem;
  }