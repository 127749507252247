/* Color Variables */
/* Color Variables */
:root {
    --primary-dark: #9B2C2C;    /* Deep red */
    --primary: #C53030;         /* Main red */
    --primary-light: #E53E3E;   /* Light red */
    --secondary-dark: #744243;  /* Dark red-grey */
    --secondary: #97666B;       /* Medium red-grey */
    --secondary-light: #BC8F96; /* Light red-grey */
    --bg-light: #FFF5F5;       /* Very light red-tinted */
    --bg-lighter: #FED7D7;     /* Even lighter red-tinted */
  }


/* Buttons */
.edit-button,
.delete-button,
.save-button,
.cancel-button,
.complete-toggle,
.progress-button,
.complete-button,
.update-page-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  color: white;
  transition: background-color 0.2s ease;
}

/* Primary buttons */
.edit-button,
.save-button,
.complete-button,
.update-page-btn,
.complete-toggle.complete {
  background-color: var(--primary);
}

.edit-button:hover,
.save-button:hover,
.complete-button:hover,
.update-page-btn:hover,
.complete-toggle.complete:hover {
  background-color: var(--primary-dark);
}

/* Secondary buttons */
.delete-button,
.cancel-button,
.progress-button,
.complete-toggle {
  background-color: var(--secondary);
}

.delete-button:hover,
.cancel-button:hover,
.progress-button:hover,
.complete-toggle:hover {
  background-color: var(--secondary-dark);
}

/* Special cases */
.progress-button,
.complete-button {
  font-size: 0.75rem;
  padding: 0.25rem 0.75rem;
}

.complete-button {
  margin-left: 0.5rem;
}

.update-page-btn {
  margin-top: 0.5rem;
}

.complete-toggle {
  padding: 0.25rem 0.75rem;
  margin-left: auto;
}

/* Disabled states */
.progress-button:disabled,
.complete-button:disabled,
.complete-toggle:disabled,
.update-page-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Past toggle button */
.past-toggle-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  color: white;
  background-color: var(--secondary);
  transition: background-color 0.2s ease;
}

.past-toggle-button:hover {
  background-color: var(--secondary-dark);
}

.past-toggle-button.active {
  background-color: var(--primary);
}

.past-toggle-button.active:hover {
  background-color: var(--primary-dark);
}


/* Main container */
.activity-list {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.activity-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

/* Controls and Filters */
.controls {
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
}

.filter-group {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.date-filter, .type-filter {
  padding: 0.75rem;
  border: 1px solid var(--secondary-light);
  border-radius: 4px;
  font-size: 1rem;
  margin: 0.5rem 0;
  box-sizing: border-box;
}

.type-filter {
  background-color: white;
  min-width: 120px;
}

.type-filter:focus {
  outline: none;
  border-color: var(--primary);
  box-shadow: 0 0 0 2px rgba(49, 130, 206, 0.25);
}

/* Past Toggle Button */
.past-toggle-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  background-color: var(--secondary);
  color: white;
}

.past-toggle-button:hover {
  background-color: var(--secondary-dark);
}

.past-toggle-button.active {
  background-color: var(--primary);
}

.past-toggle-button.active:hover {
  background-color: var(--primary-dark);
}

/* Sort Buttons */
.sort-buttons {
  display: flex;
  gap: 0.5rem;
}

.sort-button {
  padding: 0.5rem 1rem;
  background-color: var(--bg-light);
  border: 1px solid var(--secondary-light);
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.sort-button:hover {
  background-color: var(--bg-lighter);
}

.sort-button.active {
  background-color: var(--bg-lighter);
  border-color: var(--secondary);
}

/* Activity Cards */
.activities {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

  
.activity-card {
  border: 1px solid var(--secondary-light);
  border-radius: 4px;
  padding: 1.5rem;
}

.activity-header {
    display: flex;
    justify-content: space-between;
    align-items: center; /* Add this */
    margin-bottom: 1rem;
    gap: 1rem; /* Add this */
   }
   
   .activity-type {
    background-color: var(--bg-light);
    padding: 0.25rem 0.75rem; /* Increase horizontal padding */
    border-radius: 4px;
    font-size: 0.875rem;
    flex-shrink: 0; /* Add this */
   }


.activity-date {
  color: var(--secondary);
}


.activity-description {
  color: var(--secondary-dark);
  line-height: 1.5;
  margin: 1rem 0;
  padding: 0 0.5rem;
}

/* Todo Controls */
.todo-controls {
    margin: 1rem 0;
    /* Removed the background-color and padding */
  }

.progress-bar-container {
    height: 20px;
    background-color: var(--bg-lighter);
    border-radius: 10px;
    position: relative;
    margin-bottom: 1rem;
    overflow: hidden;
  }

.progress-bar {
  height: 100%;
  background-color: var(--primary);
  border-radius: 10px;
  transition: width 0.3s ease;
}

.progress-text {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--secondary-dark);
  font-size: 0.875rem;
  font-weight: bold;
}

.progress-buttons {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

.progress-button {
  padding: 0.25rem 0.5rem;
  background-color: var(--secondary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.75rem;
}

.complete-button {
  padding: 0.25rem 0.75rem;
  background-color: var(--primary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.75rem;
  margin-left: 0.5rem;
}

.progress-button:disabled,
.complete-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.progress-button:hover:not(:disabled) {
  background-color: var(--secondary-dark);
}

.complete-button:hover:not(:disabled) {
  background-color: var(--primary-dark);
}

/* Status Indicator */
.status-indicator {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0.75rem;
  border-radius: 15px;
  font-size: 0.875rem;
  background-color: white;
  border: 1px solid var(--secondary-light);
}

.status-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid currentColor;
}

.status-indicator.complete {
  color: var(--primary-dark);
}

.status-indicator.incomplete {
  color: var(--secondary);
}

.status-indicator.in-progress {
  color: var(--primary-light);
}

/* Edit Form */
.edit-form {
  margin-top: 1rem;
}

.edit-textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--secondary-light);
  border-radius: 4px;
  margin: 0.75rem 0;
  min-height: 100px;
  box-sizing: border-box;
}

.edit-buttons {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
}

/* Action Buttons */
.activity-actions {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  margin-top: 1rem;
}

.edit-button,
.delete-button,
.save-button,
.cancel-button {
  padding: 0.25rem 0.75rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  color: white;
}

.edit-button {
  background-color: var(--primary);
}

.edit-button:hover {
  background-color: var(--primary-dark);
}

.delete-button {
  background-color: var(--secondary);
}

.delete-button:hover {
  background-color: var(--secondary-dark);
}

.save-button {
  background-color: var(--primary);
}

.save-button:hover {
  background-color: var(--primary-dark);
}

.cancel-button {
  background-color: var(--secondary);
}

.cancel-button:hover {
  background-color: var(--secondary-dark);
}

.no-activities {
  text-align: center;
  color: var(--secondary);
  padding: 1rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .controls {
    flex-direction: column;
    align-items: stretch;
  }
  
  .filter-group {
    flex-direction: column;
  }
  
  .type-filter,
  .date-filter,
  .past-toggle-button {
    width: 100%;
  }

  .sort-buttons {
    width: 100%;
    justify-content: space-between;
  }
}

/* Field Display Formatting */
.activity-details {
    margin: 1rem 0;
  }
  
  .activity-details p {
    color: var(--secondary-dark);
    line-height: 1.5;
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  /* Meeting Times */
  .time-details {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
  }
  
  .time-details span {
    color: var(--secondary-dark);
    font-size: 1rem;
    font-family: inherit;
    font-weight: normal;
  }
  
  /* All fields styling */
  .activity-details strong {
    font-weight: 500;
    min-width: 80px; /* Gives consistent alignment for labels */
  }
  
  /* Payment and Amount formatting */
  .expense-amount,
  .payment-amount {
    font-size: 1rem;
    font-family: inherit;
    font-weight: normal;
    color: var(--secondary-dark);
  }


/* Button containers */
.progress-buttons {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .edit-buttons {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
    margin-top: 1rem;
  }
  
  .activity-actions {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
    margin-top: 1rem;
  }
  