.time-tracker {
    background: white;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .time-tracker-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .time-tracker-header h2 {
    margin: 0;
    font-size: 1.75rem;
    font-weight: bold;
    color: #1f2937;
  }
  
  .edit-button, .cancel-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.2s;
  }
  
  .edit-button {
    background-color: #3b82f6;
    color: white;
  }
  
  .edit-button:hover {
    background-color: #2563eb;
  }
  
  .cancel-button {
    background-color: #6b7280;
    color: white;
    margin-left: 0.5rem;
  }
  
  .cancel-button:hover {
    background-color: #4b5563;
  }
  
  .dob-edit {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .dob-edit input {
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 4px;
  }
  
  .time-stats {
    display: grid;
    gap: 1.5rem;
  }
  
  .stat-card {
    background: #f3f4f6;
    padding: 1.5rem;
    border-radius: 8px;
    border-left: 4px solid;
  }
  
  .conscious-time {
    border-left-color: #3b82f6;
  }
  
  .career-time {
    border-left-color: #10b981;
  }
  
  .stat-main h3 {
    margin: 0 0 1rem 0;
    font-size: 1.1rem;
    color: #374151;
  }
  
  .stat-value {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1f2937;
    margin: 0;
    font-family: monospace;
  }
  
  .stat-seconds {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #e5e7eb;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .seconds-label {
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .seconds-value {
    font-size: 1.25rem;
    font-weight: 500;
    color: #374151;
    font-family: monospace;
  }
  
  .no-dob {
    color: #6b7280;
    text-align: center;
    padding: 1rem;
    font-size: 1.1rem;
  }

  /* Add these styles to your existing TimeTracker.css */

.dob-edit {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-width: 200px;
  }
  
  .dob-input {
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    font-size: 1rem;
    width: 100%;
  }
  
  .dob-buttons {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
  }
  
  .clear-button,
  .save-button,
  .cancel-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.2s;
  }
  
  .clear-button {
    background-color: #ef4444;
    color: white;
  }
  
  .clear-button:hover {
    background-color: #dc2626;
  }
  
  .save-button {
    background-color: #10b981;
    color: white;
  }
  
  .save-button:hover {
    background-color: #059669;
  }
  
  .save-button:disabled {
    background-color: #d1d5db;
    cursor: not-allowed;
  }
  
  .cancel-button {
    background-color: #6b7280;
    color: white;
  }
  
  .cancel-button:hover {
    background-color: #4b5563;
  }
  
  /* Update the time-tracker-header for better layout with the new DOB edit form */
  .time-tracker-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1.5rem;
    flex-wrap: wrap;
    gap: 1rem;
  }


/* Add these CSS styles to TimeTracker.css */
.progress-container {
    margin: 20px 0;
  }
  
  .career-percentage {
    font-size: 2.5rem;
    font-weight: bold;
    color: #2563eb;
    margin: 10px 0;
  }
  
  .progress-bar2 {
    width: 100%;
    height: 12px;
    background-color: #e5e7eb;
    border-radius: 6px;
    overflow: hidden;
    margin: 10px 0;
  }
  
  .progress-fill {
    height: 100%;
    background-color: #2563eb;
    transition: width 0.3s ease;
  }
  
  .next-percent {
    background-color: #f3f4f6;
    padding: 15px;
    border-radius: 8px;
    margin: 15px 0;
  }
  
  .next-percent-heading {
    color: #4b5563;
    font-size: 0.9rem;
    margin-bottom: 8px;
  }
  
  .next-percent-time {
    font-size: 1.2rem;
    font-weight: 600;
    color: #1f2937;
    margin-bottom: 4px;
  }
  
  .next-percent-seconds {
    font-size: 0.9rem;
    color: #6b7280;
  }
  
  .stat-seconds {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #e5e7eb;
  }

  .next-percent-time {
    color: #6b7280;
    font-size: 0.9rem;
  }
  
  .next-percent-note {
    color: #1f2937;
    font-size: 1.1rem;
  }