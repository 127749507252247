/* src/components/DietAnalysis.css */
.diet-analysis {
    background: white;
    padding: 1.5rem;
    border-radius: 8px;
    margin: 10px 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .diet-analysis.loading,
  .diet-analysis.error {
    text-align: center;
    padding: 2rem;
  }
  
  .diet-analysis.error p {
    color: #dc3545;
  }
  
  .diet-analysis h3 {
    margin-bottom: 1rem;
    color: #333;
  }
  
  .macro-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .macro-card {
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
  }
  
  .macro-card.calories { background-color: #FEE2E2; }
  .macro-card.protein { background-color: #DBEAFE; }
  .macro-card.carbs { background-color: #D1FAE5; }
  .macro-card.fat { background-color: #FEF3C7; }
  
  .food-item {
    background-color: #f8f9fa;
    padding: 1.5rem;
    margin-bottom: 1rem;
    border-radius: 8px;
    border-left: 4px solid #dc3545;
  }
  
  .food-item h5 {
    margin: 0 0 1rem 0;
    color: #333;
    font-size: 1.1rem;
  }
  
  .food-macros {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 1rem;
  }
  
  .macro {
    background-color: white;
    padding: 0.75rem;
    border-radius: 6px;
    text-align: center;
  }
  
  .macro .macro-label {
    display: block;
    color: #666;
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
  }
  
  .macro .macro-value {
    display: block;
    color: #333;
    font-weight: 500;
    font-size: 1.1rem;
  }
  
  .suggestions-section {
    margin-top: 2rem;
  }
  
  .suggestions-section ul {
    margin: 1rem 0;
    padding-left: 1.5rem;
  }
  
  .suggestions-section li {
    margin-bottom: 0.5rem;
    color: #666;
  }