.activity-form {
    background: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
  }
  
  .activity-form h2 {
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #555;
  }
  
  .form-group input,
  .form-group select,
  .form-group textarea {
      width: 100%;
      padding: 0.75rem;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 1rem;
      margin: 0.5rem 0; /* Added margin */
      box-sizing: border-box; /* Ensures padding doesn't affect width */
  }
  
  .form-group textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .submit-button {
    width: 100%;
    padding: 0.75rem;
    background-color: #dc3545; /* Changed from #28a745 to red */
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 1rem;
}

.submit-button:hover {
    background-color: #c82333; /* Darker red on hover */
}

